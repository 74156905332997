<template>
  <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <a-card
      hoverable
      size="small"
      class="border"
      :id="record?.fileName + '-card'"
    >
      <a-row class="video-container">
        <a-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          class="d-flex align-items-start mb-2"
        >
          <div class="video_thumbnail" @click="handleClickThumbnail">
            <img
              v-if="record?.thumbnail_path"
              class="o-video-img h-100"
              :src="thumbnailUrl(record.thumbnail_path)"
              :alt="record.fileName"
              :id="'thumbnail-' + record?.fileName"
            />
            <div v-else class="w-100 h-100 d-flex">
              <a-spin class="m-auto" size="small" />
            </div>
          </div>
          <div class="ml-2 w-100">
            <div class="mt-1 d-flex align-items-start">
              File Name :
              <a-tag class="ml-1" color="blue"> {{ record.fileName }} </a-tag>

              <a-tag color="red">{{ getVideoTypeTagText() }}</a-tag>
              <!-- <span> -->
              <a-rate
                style="margin-left: auto;margin-top: -0.5em;font-size: 2.5em;"
                v-model:value="this.record.starred"
                count="1"
                @change="handleBookmark"
              />
              <!-- </span> -->
            </div>
            <div class="mb-1">
              Duration :
              <a-tag color="blue">{{ record.duration }} </a-tag>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="border p-2">
          <a-list
            :data-source="comments"
            item-layout="horizontal"
            :style="
              `max-height: ${comments.length ? 150 : 0}px; overflow: auto`
            "
            size="small"
            :id="record?.fileName + '-comments-list'"
            class="p-0"
          >
            <template #renderItem="{ item, index }">
              <a-list-item class="p-1">
                <a-comment
                  :author="item.user_id.username"
                  :content="item.comment"
                  :id="record?.fileName + '-comment-' + index"
                >
                  <template
                    #actions
                    v-if="isAssociateUserComment(item.user_id)"
                  >
                    <delete-outlined
                      @click="deleteComment(item)"
                      class="text-danger mt-0"
                      :id="record?.fileName + '-comment-delte-btn-' + index"
                    />
                  </template>
                </a-comment>
              </a-list-item>
            </template>
          </a-list>
          <a-form
            class="mt-4"
            layout="vertical"
            :model="comment"
            @finish="handleAddNewComment"
          >
            <a-form-item class="mb-2">
              <a-textarea
                placeholder="New Comment"
                v-model:value="comment.value"
                :rows="3"
                :id="record?.fileName + '-new-comment-input'"
              />
            </a-form-item>
            <a-form-item class="mb-2">
              <a-button
                html-type="submit"
                type="primary"
                :loading="addingComment"
                :id="record?.fileName + '-add-comment-btn'"
              >
                Add Comment
              </a-button>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-card>
  </a-col>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DeleteOutlined } from '@ant-design/icons-vue';
import VideoService from 'src/services/videos';

export default {
  components: {
    DeleteOutlined,
  },
  props: ['item'],
  setup() {
    const userId = localStorage.getItem('id');
    const userName = localStorage.getItem('name');
    return { userId, userName };
  },
  data() {
    return {
      addingComment: false,
      bookmark: true,
      record: {},
      comments: [],
      comment: {
        value: '',
      },
    };
  },
  beforeMount() {
    this.comments = this.item.comments;
    this.record = this.item;
  },

  watch: {
    item(value) {
      if (!value) return;
      this.comments = value.comments;
      this.record = value;
    },
  },
  computed: {
    ...mapGetters(['user', 'organization']),
  },
  methods: {
    ...mapActions(['updateVideoItem']),
    thumbnailUrl(thumbnail_path) {
      return (
        'https://retrocausal-video-thumbnails.s3.amazonaws.com/' +
        thumbnail_path +
        '512.jpg'
      );
    },
    handleBookmark(star) {
      VideoService.updateTaskRecord(this.record.id, {
        starred: star == 1 ? true : false,
      });
    },
    isAssociateUserComment(user_data) {
      return user_data.id == this.userId && user_data.username == this.userName
        ? true
        : false;
    },

    getVideoTypeTagText() {
      return this.item.isUploadedVideo ? 'Training' : 'Trace';
    },

    async handleAddNewComment() {
      if (!this.comment.value) return;
      this.addingComment = true;
      // add entity if not present
      if (!this.record.entity_id) {
        const res = await this.addNewEntity();
        const temp = { ...this.record };
        temp['entity_id'] = res.id;
        this.record = temp;
      }

      // create comment
      const comment_res = await this.createComment();
      comment_res['user_id'] = { id: comment_res.user_id, username: this.user };
      this.comments = [...this.comments, comment_res];

      // update task record
      const { entity_id, no_of_comments } = this.record;
      const [error] = await this.updateTaskRecord(
        { entity_id: entity_id, no_of_comments: no_of_comments + 1 },
        this.record
      );
      if (error) return;
      this.addingComment = false;
      this.comment.value = '';
      this.updateVideoItem({
        ...this.item,
        comments: this.comments,
        no_of_comments: no_of_comments + 1,
      });
    },

    async addNewEntity() {
      const payload = {
        name: this.record.fileName,
        organization: this.organization,
      };
      return await VideoService.createEntityForVideo(payload);
    },

    async createComment() {
      const payload = {
        comment: this.comment.value,
        entity_id: this.record.entity_id,
        user_id: this.userId,
      };
      return await VideoService.addNewComment(payload);
    },

    async updateTaskRecord(updatedData, videoToUpdate) {
      return await VideoService.updateTaskRecord(videoToUpdate.id, updatedData);
    },

    async deleteComment(comment) {
      this.comments = this.comments.filter((t) => t.id !== comment.id);
      const [error, _] = await VideoService.deleteComment(comment.id);
      if (error) {
        console.log({ error });
        return;
      }
      VideoService.decreaseCommentCount(this.item);
      this.updateVideoItem({
        ...this.item,
        comments: this.comments,
        no_of_comments: this.item.no_of_comments - 1,
      });
    },

    isAssociateUserComment(user_data) {
      return user_data.id == this.userId && user_data.username == this.userName
        ? true
        : false;
    },

    handleClickThumbnail() {
      this.$emit('handlePlay', this.record);
    },
  },
};
</script>
<style>
.video_thumbnail {
  height: 100px;
  width: 100px;
  position: relative;
}

.o-video-img {
  width: 100px;
  border-radius: 5px;
  cursor: pointer !important;
}

.rating {
  justify-content: right;
}

.ant-comment .ant-comment-inner {
  padding: 4px !important;
}

.ant-comment-actions {
  margin-top: 6px;
}
</style>
